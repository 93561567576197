import React, {useContext} from "react"

import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image"
import Carrousel from "../components/Carrousel";
import SEO from "../components/seo";
import {ReferrerContext} from "../context/ReferrerContext";

const ShoppingPackage = ({shoppingPackage, url}) => <Link className="border flex flex-col"
                                                          to={url}>
  <Img key={shoppingPackage.id} fluid={shoppingPackage.image.fluid}/>
  <h1 className='font-title text-xl uppercase tracking-wide mx-2 mt-2'>{shoppingPackage.name}</h1>
  <span className="font-sans font-light text-red-500 mx-2">{shoppingPackage.city.name}</span>
</Link>


const ShoppingPackagesPage = ({
                                data: {
                                  allDatoCmsShoppingpackage: shoppingPackages,
                                  datoCmsShoppingpackagespage: p,
                                  allDatoCmsBlog: blogs
                                }
                              }) => {
  const {referrer} = useContext(ReferrerContext);
  console.log('hier')

  const featuredIds = p.featured.map(p => p.id)
  const sortedShoppingPackages = shoppingPackages
    .edges
    .map(v => v.node)
    .filter(p => !featuredIds.includes(p.id))

  const compare = (a, b) => {
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }
  sortedShoppingPackages.sort((a, b) => {
    if (a.city === null && b.city === null) {
      return compare(a.name, b.name)
    }
    if (a.city === null) {
      return 1;
    }
    if (b.city === null) {
      return -1;
    }
    const r = compare(a.city.name, b.city.name);
    if (r === 0) {
      return compare(a.name, b.name)
    }
    return r;
  })

  const createUrl = slug => `/shopping-packages/${slug}/` + (referrer ? `?referrer=${referrer}` : '')

  return (
    <Layout>
      <SEO title='Shopping Packages' seo={p.seoMetaTags}/>
      <Img fluid={p.image.fluid}/>
      <div className='flex flex-col items-center md:mt-8 mx-2 md:mx-16'>
        <div className="flex flex-wrap w-full">
          <div className="w-full md:w-2/3 md:pr-6">
            <h1 className='font-title text-2xl uppercase mt-8 mb-2 tracking-wide'>Featured Packages</h1>
            <Carrousel>{p.featured.map(sp => <ShoppingPackage key={sp.id} shoppingPackage={sp}
                                                              url={createUrl(sp.slug)}/>)}</Carrousel>
          </div>
          <div className="md:w-1/3">
            <h1 className='font-title text-2xl uppercase mt-8 mb-2 tracking-wide'>Our blog</h1>
            <div className="flex flex-col justify-between">
              {blogs.edges.map(({node: b}) => (
                <Link key={b.id} className="flex w-full mb-4" to={'/blogs/' + b.slug + '/'}>
                  <Img style={{minWidth: 60}} fluid={b.image.fluid}/>
                  <div className="font-sans text-purple-800 bg-gray-200 p-4 w-full">{b.title}</div>
                </Link>))}
            </div>
          </div>
        </div>
        <h1 className='font-title text-2xl uppercase mt-16 tracking-wide'>Other Packages</h1>
        <div className='mb-8 flex flex-wrap justify-center w-full'>
          {sortedShoppingPackages
            .map((shoppingPackage) =>
              (
                <Link className='flex-1 mx-2 my-2 no-underline'
                      to={createUrl(shoppingPackage.slug)}
                      key={shoppingPackage.id}>
                  <Img className='mb-2' style={{minWidth: 200, maxWidth: 450}} fluid={shoppingPackage.image.fluid}/>
                  <div className="flex flex-col">
                    <span className='uppercase text-black font-title font-light text-base'>{shoppingPackage.name}</span>
                    {shoppingPackage.city && <span
                      className='text-gray-700 font-sans font-light text-base'>{shoppingPackage.city.name}</span>}
                  </div>
                </Link>
              )
            )}
        </div>
      </div>
    </Layout>
  );
}

export default ShoppingPackagesPage

export const query = graphql`
    query ShoppingPackages {
        allDatoCmsShoppingpackage(filter: {image: {url: {ne: null}}},sort:{fields:[city___name]}) {
            edges {
                node {
                    id
                    name
                    slug
                    city {
                        name
                    }
                    image {
                        fluid(maxWidth: 200, imgixParams: {fit: "crop", h: "200", w: "200"}) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
        datoCmsShoppingpackagespage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            image {
                fluid(maxWidth: 2000, imgixParams: {fit: "crop", h: "600", w: "2000", crop: "bottom"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
            featured {
                name
                id
                slug
                city {
                    name
                }
                image {
                    fluid(maxWidth: 1000, imgixParams: {fit: "crop", h: "400", w: "1000"}) {
                        ...GatsbyDatoCmsFluid
                    }
                }
            }
        }
        allDatoCmsBlog(sort: {fields: [meta___createdAt], order: DESC}, limit:4){
            edges{
                node{
                    id
                    title
                    slug
                    category
                    image {
                        fluid(maxWidth: 60, imgixParams: { fit: "crop", h: "80", w: "60" }) {
                            ...GatsbyDatoCmsFluid
                        }
                    }
                }
            }
        }
    }
`
