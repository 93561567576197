import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

const Carrousel = ({children}) => {
  const [index, setIndex] = useState(0)
  const n = children.length
  return <div className='w-full flex flex-col justify-center'>
    {children[index]}
    <div className="flex justify-center py-1">
      {Array.from(Array(n).keys()).map(i =>
        <FontAwesomeIcon key={i} className={'mr-1 ' + (i === index ? 'text-gray-400' : 'text-gray-800')}
                         onClick={() => setIndex(i)} icon={faCircle} size="xs"/>)
      }

    </div>
  </div>
}

export default Carrousel